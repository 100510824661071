.pane-wcm-grad-news {
  background-color: $wcm-red;

  h2 {
    color: $wcm-white;
    font-family: $wcm-regular;
    font-size: 32px;
    margin: 30px 0 25px 0;
  }

  @include breakpoint($xs) {
    text-align: center;
  }

  &__container {
    @include container-wrap();

    &__inner {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .grad-news {
        flex-basis: calc(33.33% - 20px);
        max-width: calc(33.33% - 20px);
        margin-bottom: 20px;

        @include breakpoint($xs) {
          flex-basis: 100%;
          max-width: 100%;
        }
      }

      @include breakpoint($xs) {
        text-align: center;
        flex-direction: column;
      }

      .grad-news__image {
        overflow: hidden;
        margin: 0 0 10px;
        img {
          width: 100%;
          transition: all 0.3s ease 0s;
          &:hover {
            transform: scale(1.1);
          }
        }
      }

      .grad-news__date {
        margin: 0 0 10px;
        color: $wcm-white;
        font-size: 15px;
      }

      .grad-news__title {
        margin: 0 0 10px;

        a {
          border-bottom-width: 0;
          font-size: 20px;
          color: $wcm-white;
          font-family: $wcm-bold;
          line-height: 1;
        }

        a:hover {
          text-decoration: underline;
        }
      }

      .grad-news__teaser {
        font-size: 15px;
        margin: 0 0 20px;
        color: $wcm-white;
      }
    }
  }
}


