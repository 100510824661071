.wcm-grad-hero {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: $wcm-white;
  background-size: cover;
  background-position: center;

  &--align-top {
    align-items: center;
    padding-top: 50px;

    @include breakpoint($xs) {
      padding-top: 0;
    }
  }

  @include breakpoint($sm) {
    min-height: calc(100vh - 310px);
  }

  @include breakpoint($xs) {
    background-image: none !important;
    color: $wcm-black;
  }

  &__container {
    margin-bottom: 25px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;

    h2 {
      color: $wcm-white;
      font-family: $wcm-regular;
      font-weight: 400;
      text-transform: uppercase;
      font-size: 50px;

      @include breakpoint($sm) {
        text-shadow: 1px 1px 1px rgba(0,0,0,0.68);
      }

      @include breakpoint($xs) {
        color: $wcm-dark-gray;
        font-size: 32px;
        margin-top: 0;
      }
    }
    h3 {
      color: $wcm-white;
      font-family: $wcm-bold;
      font-weight: 800;
      text-transform: uppercase;
      font-size: 50px;
      margin: 0;

      @include breakpoint($sm) {
        text-shadow: 1px 1px 1px rgba(0,0,0,0.68);
      }

      @include breakpoint($xs) {
        color: $wcm-dark-gray;
        font-size: 32px;
        margin-bottom: 20px;
      }
    }
    span {
      color: $wcm-white;
      @include breakpoint($xs) {
        color: $wcm-dark-gray;
      }
      font-family: $wcm-regular;
      font-weight: 400;
      font-size: 18px;
      line-height: 3;

      @include breakpoint($xs) {
        font-family: $wcm-regular;
      }
    }

    &__video {
      position: relative;
      padding-bottom: calc(56.25% - 25px);
      height: 0;
      overflow: hidden;
      max-width: 100%;
      border: 20px solid #3A3A3A;
      background-color: #3A3A3A;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

  }
}
