/**
 * Styles for the WCM Slide Navigation
 *
 */

// Bring in a few things from Bootstrap so we can use the grid, clearfix and vendor mixins (for transition, etc)
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/variables";
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/mixins/_clearfix";
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/mixins/_grid";
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/mixins/_vendor-prefixes";

// Bring in the vars and custom mixins defined in the WCMC Bootstrap theme. Sets the WCMC Colors and Breakpoints
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/_variables";
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/_mixins";

/* =WCM Graduation 2024 Landing Item
----------------------------------------------------------*/

// Paragraphs
@import "hero";
@import "program";
@import "events";
@import "news";

.wcm-grad-photo-galleries-heading {
  padding: 25px 0 20px 0;
  background-color: $wcm-bg-gray;

  .fieldable-panels-pane {
    @include container-wrap();

    p {
      text-align: center;
      font-size: 32px;
      color: $wcm-dark-gray;
      margin: 0;
      line-height: 1.2;
    }
  }
}

.wcm-grad-photo-galleries {
  background-color: $wcm-bg-gray;

  @include breakpoint($xs) {
    .card-callout {
      margin: 0;
      .card-callout__text {
        padding-bottom: 10px;
      }
    }
  }
}

.wcm-grad-social {
  @include container-wrap();
  padding: 25px 0 10px 0;
  text-align: center;
  font-size: 28px;
  color: $wcm-dark-gray;

  @include breakpoint($xs) {
    font-size: 20px;
  }

  p {
    margin: 0;
    line-height: 1.2;
  }
}
