/**
 * Styles for the WCM Slide Navigation
 *
 */
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =Responsive iFrames
----------------------------------------------------------*/
/* =WCM Graduation 2024 Landing Item
----------------------------------------------------------*/
.wcm-grad-hero {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: #fff;
  background-size: cover;
  background-position: center;
}
.wcm-grad-hero--align-top {
  align-items: center;
  padding-top: 50px;
}
@media screen and (max-width: 767px) {
  .wcm-grad-hero--align-top {
    padding-top: 0;
  }
}
@media screen and (min-width: 768px) {
  .wcm-grad-hero {
    min-height: calc(100vh - 310px);
  }
}
@media screen and (max-width: 767px) {
  .wcm-grad-hero {
    background-image: none !important;
    color: #000000;
  }
}
.wcm-grad-hero__container {
  margin-bottom: 25px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}
.wcm-grad-hero__container h2 {
  color: #fff;
  font-family: "1898Sans-Regular", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 50px;
}
@media screen and (min-width: 768px) {
  .wcm-grad-hero__container h2 {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.68);
  }
}
@media screen and (max-width: 767px) {
  .wcm-grad-hero__container h2 {
    color: #555555;
    font-size: 32px;
    margin-top: 0;
  }
}
.wcm-grad-hero__container h3 {
  color: #fff;
  font-family: "1898Sans-Bold", sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 50px;
  margin: 0;
}
@media screen and (min-width: 768px) {
  .wcm-grad-hero__container h3 {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.68);
  }
}
@media screen and (max-width: 767px) {
  .wcm-grad-hero__container h3 {
    color: #555555;
    font-size: 32px;
    margin-bottom: 20px;
  }
}
.wcm-grad-hero__container span {
  color: #fff;
  font-family: "1898Sans-Regular", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 3;
}
@media screen and (max-width: 767px) {
  .wcm-grad-hero__container span {
    color: #555555;
  }
}
@media screen and (max-width: 767px) {
  .wcm-grad-hero__container span {
    font-family: "1898Sans-Regular", sans-serif;
  }
}
.wcm-grad-hero__container__video {
  position: relative;
  padding-bottom: calc(56.25% - 25px);
  height: 0;
  overflow: hidden;
  max-width: 100%;
  border: 20px solid #3A3A3A;
  background-color: #3A3A3A;
}
.wcm-grad-hero__container__video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.wcm-grad-program {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  background-color: #F7F7F7;
  background-image: linear-gradient(-7deg, #F7F7F7 50%, #F2F2F2 50%);
  min-height: 590px;
}
@media screen and (max-width: 767px) {
  .wcm-grad-program {
    padding-top: 25px;
    background-image: linear-gradient(-25deg, #F7F7F7 50%, #F2F2F2 50%);
  }
}
.wcm-grad-program__desc {
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 18px;
  color: #555555;
  text-align: center;
}
.wcm-grad-program__desc:before, .wcm-grad-program__desc:after {
  content: " ";
  display: table;
}
.wcm-grad-program__desc:after {
  clear: both;
}
@media screen and (min-width: 768px) {
  .wcm-grad-program__desc {
    width: 750px;
  }
}
@media screen and (min-width: 992px) {
  .wcm-grad-program__desc {
    width: 970px;
  }
}
@media screen and (min-width: 1200px) {
  .wcm-grad-program__desc {
    width: 1170px;
  }
}
@media screen and (max-width: 767px) {
  .wcm-grad-program__desc {
    text-align: center;
    font-size: 16px;
  }
}
.wcm-grad-program__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  column-gap: 30px;
  margin: 0 auto;
  max-width: 1200px;
  padding: 50px 20px;
}
@media screen and (max-width: 767px) {
  .wcm-grad-program__inner {
    flex-direction: column;
    text-align: center;
    font-size: 16px;
    padding: 15px 20px;
  }
}
.wcm-grad-program__inner__image img {
  max-width: 100%;
  border: 20px solid #f7f7f7;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.16);
}
.wcm-grad-program__inner__info {
  color: #262626;
  max-width: 425px;
}
.wcm-grad-program__inner__info a {
  font-size: 20px;
  font-family: "1898Sans-Bold", sans-serif;
}

.wcm-grad-events {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
@media screen and (max-width: 767px) {
  .wcm-grad-events {
    text-align: center;
  }
}
.wcm-grad-events__container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px;
  color: #555555;
}
.wcm-grad-events__container:before, .wcm-grad-events__container:after {
  content: " ";
  display: table;
}
.wcm-grad-events__container:after {
  clear: both;
}
@media screen and (min-width: 768px) {
  .wcm-grad-events__container {
    width: 750px;
  }
}
@media screen and (min-width: 992px) {
  .wcm-grad-events__container {
    width: 970px;
  }
}
@media screen and (min-width: 1200px) {
  .wcm-grad-events__container {
    width: 1170px;
  }
}
.wcm-grad-events__container h2 {
  color: #555555;
  font-family: "1898Sans-Regular", sans-serif;
  margin-bottom: 10px;
}
.wcm-grad-events__container__events__event:not(:last-child) {
  border-bottom: 1px solid #dddddd;
}
.wcm-grad-events__container__events__event__date {
  font-size: 14px;
  margin-top: 15px;
}
.wcm-grad-events__container__events__event h3 {
  color: #555555;
  margin-top: 10px;
}
.wcm-grad-events__container__events__event__desc {
  font-size: 15px;
  margin-top: 5px;
}
.wcm-grad-events__container__events__event__loc {
  font-size: 13px;
  font-family: "1898Sans-Bold", sans-serif;
  margin-bottom: 20px;
}

.pane-wcm-grad-news {
  background-color: #b31b1b;
}
.pane-wcm-grad-news h2 {
  color: #fff;
  font-family: "1898Sans-Regular", sans-serif;
  font-size: 32px;
  margin: 30px 0 25px 0;
}
@media screen and (max-width: 767px) {
  .pane-wcm-grad-news {
    text-align: center;
  }
}
.pane-wcm-grad-news__container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px;
}
.pane-wcm-grad-news__container:before, .pane-wcm-grad-news__container:after {
  content: " ";
  display: table;
}
.pane-wcm-grad-news__container:after {
  clear: both;
}
@media screen and (min-width: 768px) {
  .pane-wcm-grad-news__container {
    width: 750px;
  }
}
@media screen and (min-width: 992px) {
  .pane-wcm-grad-news__container {
    width: 970px;
  }
}
@media screen and (min-width: 1200px) {
  .pane-wcm-grad-news__container {
    width: 1170px;
  }
}
.pane-wcm-grad-news__container__inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.pane-wcm-grad-news__container__inner .grad-news {
  flex-basis: calc(33.33% - 20px);
  max-width: calc(33.33% - 20px);
  margin-bottom: 20px;
}
@media screen and (max-width: 767px) {
  .pane-wcm-grad-news__container__inner .grad-news {
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .pane-wcm-grad-news__container__inner {
    text-align: center;
    flex-direction: column;
  }
}
.pane-wcm-grad-news__container__inner .grad-news__image {
  overflow: hidden;
  margin: 0 0 10px;
}
.pane-wcm-grad-news__container__inner .grad-news__image img {
  width: 100%;
  transition: all 0.3s ease 0s;
}
.pane-wcm-grad-news__container__inner .grad-news__image img:hover {
  transform: scale(1.1);
}
.pane-wcm-grad-news__container__inner .grad-news__date {
  margin: 0 0 10px;
  color: #fff;
  font-size: 15px;
}
.pane-wcm-grad-news__container__inner .grad-news__title {
  margin: 0 0 10px;
}
.pane-wcm-grad-news__container__inner .grad-news__title a {
  border-bottom-width: 0;
  font-size: 20px;
  color: #fff;
  font-family: "1898Sans-Bold", sans-serif;
  line-height: 1;
}
.pane-wcm-grad-news__container__inner .grad-news__title a:hover {
  text-decoration: underline;
}
.pane-wcm-grad-news__container__inner .grad-news__teaser {
  font-size: 15px;
  margin: 0 0 20px;
  color: #fff;
}

.wcm-grad-photo-galleries-heading {
  padding: 25px 0 20px 0;
  background-color: #f7f7f7;
}
.wcm-grad-photo-galleries-heading .fieldable-panels-pane {
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px;
}
.wcm-grad-photo-galleries-heading .fieldable-panels-pane:before, .wcm-grad-photo-galleries-heading .fieldable-panels-pane:after {
  content: " ";
  display: table;
}
.wcm-grad-photo-galleries-heading .fieldable-panels-pane:after {
  clear: both;
}
@media screen and (min-width: 768px) {
  .wcm-grad-photo-galleries-heading .fieldable-panels-pane {
    width: 750px;
  }
}
@media screen and (min-width: 992px) {
  .wcm-grad-photo-galleries-heading .fieldable-panels-pane {
    width: 970px;
  }
}
@media screen and (min-width: 1200px) {
  .wcm-grad-photo-galleries-heading .fieldable-panels-pane {
    width: 1170px;
  }
}
.wcm-grad-photo-galleries-heading .fieldable-panels-pane p {
  text-align: center;
  font-size: 32px;
  color: #555555;
  margin: 0;
  line-height: 1.2;
}

.wcm-grad-photo-galleries {
  background-color: #f7f7f7;
}
@media screen and (max-width: 767px) {
  .wcm-grad-photo-galleries .card-callout {
    margin: 0;
  }
  .wcm-grad-photo-galleries .card-callout .card-callout__text {
    padding-bottom: 10px;
  }
}

.wcm-grad-social {
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px;
  padding: 25px 0 10px 0;
  text-align: center;
  font-size: 28px;
  color: #555555;
}
.wcm-grad-social:before, .wcm-grad-social:after {
  content: " ";
  display: table;
}
.wcm-grad-social:after {
  clear: both;
}
@media screen and (min-width: 768px) {
  .wcm-grad-social {
    width: 750px;
  }
}
@media screen and (min-width: 992px) {
  .wcm-grad-social {
    width: 970px;
  }
}
@media screen and (min-width: 1200px) {
  .wcm-grad-social {
    width: 1170px;
  }
}
@media screen and (max-width: 767px) {
  .wcm-grad-social {
    font-size: 20px;
  }
}
.wcm-grad-social p {
  margin: 0;
  line-height: 1.2;
}