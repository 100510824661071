.wcm-grad-events {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  @include breakpoint($xs) {
    text-align: center;
  }

  &__container {
    @include container-wrap();
    color: $wcm-dark-gray;

    h2 {
      color: $wcm-dark-gray;
      font-family: $wcm-regular;
      margin-bottom: 10px;
    }

    &__events__event {
      &:not(:last-child) {
        border-bottom: 1px solid $wcm-border-gray;
      }

      &__date {
        font-size: 14px;
        margin-top: 15px;
      }

      h3 {
        color: $wcm-dark-gray;
        margin-top: 10px;
      }

      &__desc {
        font-size: 15px;
        margin-top: 5px;
      }

      &__loc {
        font-size: 13px;
        font-family: $wcm-bold;
        margin-bottom: 20px;
      }
    }
  }
}
