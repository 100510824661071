.wcm-grad-program {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  background-color: #F7F7F7;
  background-image: linear-gradient(-7deg, #F7F7F7 50%, #F2F2F2 50%);
  min-height: 590px;

  @include breakpoint($xs) {
    padding-top: 25px;
    background-image: linear-gradient(-25deg, #F7F7F7 50%, #F2F2F2 50%);
  }

  &__desc {
    @include container-wrap();
    font-size: 18px;
    color: $wcm-dark-gray;
    text-align: center;

    @include breakpoint($xs) {
      text-align: center;
      font-size: 16px;
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    column-gap: 30px;
    margin: 0 auto;
    max-width: 1200px;
    padding: 50px 20px;

    @include breakpoint($xs) {
      flex-direction: column;
      text-align: center;
      font-size: 16px;
      padding: 15px 20px;
    }

    &__image {
      img {
        max-width: 100%;
        border: 20px solid $wcm-bg-gray;
        // Add drop shadow to image
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.16);
      }
    }

    &__info {
      color: #262626;
      max-width: 425px;
      a {
        font-size: 20px;
        font-family: $wcm-bold;
      }
    }

  }
}
